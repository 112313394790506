export default {
  siteTitle: 'Get bonus',
  blogerName: 'ZAZA',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/hellozazaa'
    },
    {
      name: 'telegram',
      url: 'https://t.me/Hellozazasohbet'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/helllozaza'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@CasinoZaZa'
    },
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/helllozaza'
    },
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/casinozaza'
    },
  ],
  projects: [
    {
      name: 'starda',
      url: 'https://stardacasino.life/cf7b5701f',
      gameTitle: 'All Lucky Clover 5 (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c821f54df',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/caa6f8c78',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    }
  ],
  headerText: 'Promosyonu kullan <strong>ZAZA</strong> aşağıdaki slotlarda 100FS almak için!',
  yourPromocode: 'Promosyon kodunuz',
  gameBonus: '',
  gameBonusAfter: 'slotunda bonus',
  promocode: 'ZAZA',
  casinoBonus1: '<strong>100% + 500FS</strong><div>İlk depozito için</div>',
  casinoBonus2: '<strong>100FS</strong><div>Promosyon koduyla kaydolmak için</div>',
  firstDeposit: 'For the first deposit',
  copyMessage: 'Promosyon kodu kopyalandı',
  theme: 'dark'
};
