<template>
  <header class="header">
    <div class="container">
      <div class="header__inner">
        <a
            v-if="!logo"
            class="header__logo" href="index.html"
        >
          <slot name="logo"></slot>
        </a>

        <div
            v-else
            class="header__logo-container"
        >
          <a
              v-for="image in logo"
              :key="image"
              class="header__logo"
              href="index.html"
          >
            <img
                :src="`img/${image}`"
                class="header__logo-image"
                alt="logo"
            />
          </a>
        </div>
        <div class="header__logo-name">{{ blogerName }}</div>
        <div class="header__social">
          <Socials :socials="config.socialsList" />
        </div>
      </div>
      <div class="header__text" v-html="headerText"></div>
    </div>
  </header>
</template>

<script>
import {defineComponent} from 'vue'
import Socials from "../socials/index.vue";

export default defineComponent({
  name: "Header",
  components: {
    Socials
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      logo: this.config.logo,
      blogerName: this.config.blogerName,
      headerText: this.config.headerText
    }
  }
})
</script>

<style lang="scss" src="./style.scss">
</style>
