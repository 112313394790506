<template>
  <notifications />
  <Tmpl :config="config" @copy="copyEvent">
    <template v-slot:logo>
      <img
          src="./assets/logo.webp"
          class="header__logo-image"
          alt="logo"
      />
    </template>
  </Tmpl>
</template>

<script>
import Tmpl from '../../shared/vue-components/simple-template'
import config from '../project.conf.js'

export default {
  name: 'App',
  components: {
    Tmpl
  },
  data () {
    return {
      config
    }
  },
  methods: {
    copyEvent () {
      this.$notify(this.config.copyMessage);
    },

  }
}
</script>

